import { config } from '../config/index'
import requestOptions from '@/helpers/RequestOptions'
import handleResponse from '@/helpers/HandleResponses'
// import handleBlobResponse from '@/helpers/HandleBlobResponses'
// import handleTextResponse from '@/helpers/HandleTextResponses'

export const miscService = {
  getSBUs,
  getBanks,
  getAirports,
  getTowns,
  getCities,
  getCentresByCity,
  getCentrePOSsBysbu,
  getCountries,
  getPrices,
  getManagers,
  getFrontlines,
  getOperators,
  getGSAOperators,
  getCentres,
  getCommodityPrices,
  getAdminUsers,
  getRoles,
  getHubs,
  getTemplates,
  getRoutines,
  getRegions,
  getRegion,
  getRegionCities,
  getExceptions,
  getExceptionsByType,
  getException,
  createAirport,
  createCountry,
  createCity,
  createTown,
  createPrice,
  createManager,
  createFrontline,
  createOperator,
  createGSAOperator,
  createCentre,
  createCentrePOS,
  createCommodityPrice,
  createAdminUser,
  createRole,
  createTemplate,
  createBroadcast,
  createHub,
  createRoutine,
  createRegion,
  createException,
  createRegionCity,
  createAirportCity,
  updateAirport,
  updateCountry,
  updateCity,
  updateTown,
  updatePrice,
  updateFrontline,
  updateManager,
  updateOperator,
  updateGSAOperator,
  updateCentre,
  updateCentrePOS,
  updateCommodityPrice,
  updateTemplate,
  updateHub,
  updateRoutine,
  updateRegion,
  updateException,
  updateRegionCity,
  updateAirportCity,
  deleteAirport,
  deleteCountry,
  deleteCity,
  deleteTown,
  deletePrice,
  deleteFrontline,
  deleteManager,
  deleteOperator,
  deleteGSAOperator,
  deleteCentre,
  deleteCentrePOS,
  deleteOnForwarding,
  removeCommodityPrice,
  removeAdminUser,
  removeTemplate,
  deleteHub,
  deleteRoutine,
  deleteRegion,
  deleteException,
  deleteRegionCity,
  deleteAirportCity,
  updateOnForwarding,
  getOnForwardings,
  importOnForwarding,
  createOnForwarding,
  updateSBU,
  importBanks,
  importTown,
  getDrivers,
  updateDriver,
  generateAllocation,
  getAllocations,
  getChargeSettings,
  createChargeSetting,
  updateChargeSetting,
  deleteChargeSetting,
  getAirportCities,
  getUnverifiedDSS,
  updateDSS,
  verifyDSS,
  updateCentreCity,
  getCentrePOSsByCenter,
  updateCentreAirport,
  getCentresByAirport
}

async function getSBUs () {
  const response = await fetch(`${config.apiurl}/Misc/SBUs`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

// async function getHubs (id) {
//   const response = await fetch(`${config.apiurl}/Misc/Hubs?sbuId=${id}`, requestOptions.get())
//   const model = await handleResponse(response)
//   return model
// }

async function updateSBU (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateSBU`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function importBanks (req) {
  const response = await fetch(`${config.apiurl}/Misc/ImportBankAccounts`, requestOptions.postForm(req))
  const model = await handleResponse(response)
  return model
}

async function getBanks (id) {
  const response = await fetch(`${config.apiurl}/Misc/BankAccounts?sbuId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getAirports () {
  const response = await fetch(`${config.apiurl}/Misc/Airports`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createAirport (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostAirport`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateAirport (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateAirport`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteAirport (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeAirport/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getCountries () {
  const response = await fetch(`${config.apiurl}/Misc/Countries`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCountry (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostCountry`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateCountry (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateCountry`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteCountry (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeCountry/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getCities () {
  const response = await fetch(`${config.apiurl}/Misc/Cities`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCity (Id, req) {
  const response = await fetch(`${config.apiurl}/Misc/PostCity/${Id}`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateCity (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateCity`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteCity (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeCity/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getTowns (id) {
  const response = await fetch(`${config.apiurl}/Misc/Towns?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createTown (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostTown`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}
async function importTown (req) {
  const response = await fetch(`${config.apiurl}/Misc/ImportTown`, requestOptions.postForm(req))
  const model = await handleResponse(response)
  return model
}

async function updateTown (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateTown`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteTown (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeTown/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getPrices () {
  const response = await fetch(`${config.apiurl}/Misc/CommodityPrices`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createPrice (req) {
  const response = await fetch(`${config.apiurl}/cMisc/PostCommodityPrice`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updatePrice (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateCommodityPrice`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deletePrice (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeCommodityPrice/${id}`, requestOptions.delete(id))
  const model = await handleResponse(response)
  return model
}

async function getFrontlines (Id) {
  const response = await fetch(`${config.apiurl}/Misc/FrontLines?Id=${Id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createFrontline (id, req) {
  const response = await fetch(`${config.apiurl}/Misc/PostFrontLine/${id}`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateFrontline (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateFrontLine`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteFrontline (Id) {
  const response = await fetch(`${config.apiurl}/Misc/removeFrontLine/${Id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getManagers (Id) {
  const response = await fetch(`${config.apiurl}/Misc/Managers?Id=${Id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createManager (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostManager`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateManager (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateManager`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteManager (Id) {
  const response = await fetch(`${config.apiurl}/Misc/removeManager/${Id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getOperators (Id) {
  const response = await fetch(`${config.apiurl}/Misc/Operators?Id=${Id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createOperator (Id, req) {
  const response = await fetch(`${config.apiurl}/Misc/PostOperator/${Id}`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateOperator (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateOperator`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteOperator (Id) {
  const response = await fetch(`${config.apiurl}/Misc/removeOperator/${Id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getGSAOperators () {
  const response = await fetch(`${config.apiurl}/Misc/GSAOperators`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createGSAOperator (Id, req) {
  const response = await fetch(`${config.apiurl}/Misc/PostGSAOperator/${Id}`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateGSAOperator (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateGSAOperator`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteGSAOperator (Id) {
  const response = await fetch(`${config.apiurl}/Misc/removeGSAOperator/${Id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getCentres (Id) {
  const response = await fetch(`${config.apiurl}/Misc/Centers?Id=${Id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCentresByCity (Id, sbuId) {
  const response = await fetch(`${config.apiurl}/Misc/CentersByCity?Id=${Id}&sbuId=${sbuId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCentresByAirport (Id, sbuId) {
  const response = await fetch(`${config.apiurl}/Misc/CentersByAirport?Id=${Id}&sbuId=${sbuId}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCentrePOSsBysbu (Id) {
  const response = await fetch(`${config.apiurl}/Misc/centerPOSsBySBuId?Id=${Id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getCentrePOSsByCenter (Id) {
  const response = await fetch(`${config.apiurl}/Misc/centerPOSs?Id=${Id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCentre (Id, req) {
  const response = await fetch(`${config.apiurl}/Misc/PostCenter/${Id}`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function createCentrePOS (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostCenterPOS`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateCentre (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateCenter`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}
async function updateCentreCity (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateCenterCity`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}
async function updateCentreAirport (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateCenterAirport`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}
async function updateCentrePOS (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateCenterPOS`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteCentre (Id) {
  const response = await fetch(`${config.apiurl}/Misc/removeCenter/${Id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function deleteCentrePOS (Id) {
  const response = await fetch(`${config.apiurl}/Misc/removeCenterPOS/${Id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getOnForwardings () {
  const response = await fetch(`${config.apiurl}/Misc/Onforwardings`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function updateOnForwarding (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateOnforwarding`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteOnForwarding (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeOnforwarding/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function importOnForwarding (req) {
  const response = await fetch(`${config.apiurl}/Misc/ImportOnforwarding`, requestOptions.postForm(req))
  const model = await handleResponse(response)
  return model
}

async function createOnForwarding (Id, req) {
  const response = await fetch(`${config.apiurl}/Misc/PostOnforwarding/${Id}`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function getCommodityPrices () {
  const response = await fetch(`${config.apiurl}/Misc/CommodityPrices`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createCommodityPrice (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostCommodityPrice`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateCommodityPrice (req) {
  const response = await fetch(`${config.apiurl}/Misc/updateCommodityPrice`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeCommodityPrice (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeCommodityPrice/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getAdminUsers (role) {
  const response = await fetch(`${config.apiurl}/Account/usersbyRole?role=${role}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createAdminUser (sbuId, role, req) {
  const response = await fetch(`${config.apiurl}/Account/addAdmin?sbuId=${sbuId}&role=${role}`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function removeAdminUser (Id) {
  const response = await fetch(`${config.apiurl}/Account/removeuser/${Id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getRoles () {
  const response = await fetch(`${config.apiurl}/Account/roles`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createRole (name) {
  const response = await fetch(`${config.apiurl}/Account/addrole`, requestOptions.post(name))
  const model = await handleResponse(response)
  return model
}

async function getTemplates () {
  const response = await fetch(`${config.apiurl}/Misc/SMSTemplates`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createTemplate (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostSMSTemplate`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function createBroadcast (req) {
  const response = await fetch(`${config.apiurl}/Misc/Broadcast`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateTemplate (req) {
  const response = await fetch(`${config.apiurl}/Misc/updateSMSTemplate`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function removeTemplate (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeSMSTemplate/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getHubs (id) {
  const response = await fetch(`${config.apiurl}/Misc/Hubs?sbuId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createHub (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostHub`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateHub (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateHub`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteHub (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeHub/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getRoutines (id) {
  const response = await fetch(`${config.apiurl}/Misc/Routines?sbuId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createRoutine (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostRoutine`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateRoutine (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateRoutine`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteRoutine (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeRoutine/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getRegions (id) {
  const response = await fetch(`${config.apiurl}/Misc/Regions?sbuId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getRegion (id) {
  const response = await fetch(`${config.apiurl}/Misc/Region?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createRegion (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostRegion`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateRegion (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateRegion`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteRegion (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeRegion/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}
async function getExceptions () {
  const response = await fetch(`${config.apiurl}/Misc/Exceptions`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function getExceptionsByType (id) {
  const response = await fetch(`${config.apiurl}/Misc/Exceptions/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function getException (id) {
  const response = await fetch(`${config.apiurl}/Misc/Exception?Id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createException (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostException`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateException (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateException`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteException (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeException/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getRegionCities (id) {
  const response = await fetch(`${config.apiurl}/Misc/RegionCities?sbuId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function getAirportCities () {
  const response = await fetch(`${config.apiurl}/Misc/AirportCities`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function getAllocations (id) {
  const response = await fetch(`${config.apiurl}/Customer/allocations?id=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
async function generateAllocation (req) {
  const response = await fetch(`${config.apiurl}/Customer/GenerateAllocation`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function createRegionCity (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostRegionCity`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateRegionCity (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateRegionCity`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteRegionCity (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeRegionCity/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function createAirportCity (req) {
  const response = await fetch(`${config.apiurl}/Misc/PostAirportCity`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateAirportCity (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateAirportCity`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteAirportCity (id) {
  const response = await fetch(`${config.apiurl}/Misc/removeAirportCity/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getDrivers (id) {
  const response = await fetch(`${config.apiurl}/Misc/Drivers?sbuId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function updateDriver (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateDriver`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function getChargeSettings () {
  const response = await fetch(`${config.apiurl}/Misc/ChargeSettings`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function createChargeSetting (id, req) {
  const response = await fetch(`${config.apiurl}/Misc/PostChargeSetting/${id}`, requestOptions.post(req))
  const model = await handleResponse(response)
  return model
}

async function updateChargeSetting (req) {
  const response = await fetch(`${config.apiurl}/Misc/UpdateChargeSetting`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function deleteChargeSetting (id) {
  const response = await fetch(`${config.apiurl}/Misc/DeleteChargeSetting/${id}`, requestOptions.delete())
  const model = await handleResponse(response)
  return model
}

async function getUnverifiedDSS (id) {
  const response = await fetch(`${config.apiurl}/Shipment/dss/unverified/${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}

async function updateDSS (req) {
  const response = await fetch(`${config.apiurl}/Shipment/updatedss`, requestOptions.put(req))
  const model = await handleResponse(response)
  return model
}

async function verifyDSS (id) {
  const response = await fetch(`${config.apiurl}/Shipment/verifyDSS?dssId=${id}`, requestOptions.get())
  const model = await handleResponse(response)
  return model
}
