<script setup>
import { authenticationService } from '@/services/AuthenticationService'
import { expenseService } from '@/services/ExpenseService'
import { useGeneralStore } from '@/stores/GeneralStore'

const genStore = useGeneralStore()
</script>
<script>

export default {
  name: 'SideBar',
  data () {
    return {
      currentUser: authenticationService.currentUserValue.user,
      operations: false,
      originHub: false,
      dbmHub: false,
      destinationHub: false,
      delivery: false,
      customer: false,
      centre: false,
      service: false,
      report: false,
      broadcast: false,
      settings: false,
      profile: false,
      shipment: false,
      admin: false,
      expenses: false,
      isManager: false
    }
  },
  computed: {
    showFSMShipmentReport () {
      const role = this.currentUser.roles[0]
      const isGlobalAdmin = role === 'Global Administrator'
      const isAdminOrManager = ['Administrator', 'Manager'].includes(role) || this.isManager
      const notSbuId3 = this.currentUser.sbuUser?.sbuId !== 3
      return isGlobalAdmin || (isAdminOrManager && notSbuId3)
    }
  },
  methods: {
    checkManager () {
      expenseService.isManager(this.currentUser.id).then(
        res => {
          console.log('IsManager? >>', res)
          this.isManager = res
        },
        err => {
          console.log(err)
        }
      )
    }
  },
  mounted () {
    this.checkManager()
  }
}
</script>

<template>
  <div :class="genStore.sidebarVisible ? 'sidebar-mm h-100 is-visible' : 'sidebar-mm h-100 collapsed'">
    <div class="sidebar-m h-100 my-4 mx-3">
      <div class="data-scroll h-100 mb-5">
        <ul class="list-unstyled">
          <li
          class="nav-list"
          v-if="currentUser.roles[0] !== 'IT Administrator'"
          >
            <router-link to="/dashboard" class="active nav-link">
              <span class="">Home</span>
            </router-link>
          </li>
          <li
          class="nav-list active"
          v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'FrontLine'"
          >
            <a
              :class="customer ? null : 'collapsed'"
              :aria-expanded="customer ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="customer = !customer"
            >
              <span class="">CUSTOMER</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="customer" class="mt-2">
              <ul class="list-unstyled">
                <li class="">
                  <router-link to="/manage-customers/index" class="nav-link">
                    Manage customers
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/corporate-customers/index" class="nav-link">
                    Corporate customers
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/discounts/index" class="nav-link">
                    Manage Discounts
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list" v-if="currentUser.roles[0] === 'FrontLine'">
            <a
              :class="shipment ? null : 'collapsed'"
              :aria-expanded="shipment ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="shipment = !shipment"
            >
              <span class="">SHIPMENT</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="shipment" class="mt-2">
              <ul class="list-unstyled">
                <li class="" v-if="currentUser?.sbuUser?.sbuId != 3">
                  <router-link to="/shipment/rsm/new" class="nav-link">RSM Shipment</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId === 1">
                  <router-link to="/shipment/intl/new" class="nav-link">Intl Shipment</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId === 3">
                  <router-link to="/shipment/frecordsale/new" class="nav-link">New Shipment</router-link>
                </li>
                <!-- <li class="" v-if="currentUser?.sbuUser?.sbuId != 3">
                  <router-link to="/shipment/home_office_shipment" class="nav-link">Home and Office</router-link>
                </li> -->
                <li class="" v-if="currentUser?.sbuUser?.sbuId == 2">
                  <router-link to="/shipment/relocations" class="nav-link">Relocations</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId != 3">
                  <router-link to="/shipment/daily/rsm" class="nav-link">Daily Shipments</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId != 3">
                  <router-link to="/shipment/daily/intl" class="nav-link">Intl Daily Shipments</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId != 3">
                  <router-link to="/shipment/dailysales" class="nav-link">Daily Sales</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId != 3">
                  <router-link to="/shipment/import" class="nav-link">Upload Shipment</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId != 3">
                  <router-link to="/shipment/pickup" class="nav-link">Pickups</router-link>
                </li>
                <li class="">
                  <router-link to="/shipment/pods" class="nav-link">PODs</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId === 1">
                  <router-link to="/shipment/importation" class="nav-link">Importation</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list"  v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'Manager'">
            <a
              :class="shipment ? null : 'collapsed'"
              :aria-expanded="shipment ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="shipment = !shipment"
            >
              <span class="">SHIPMENT</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="shipment" class="mt-2">
              <ul class="list-unstyled">
                <li class="">
                  <router-link to="/shipment/discrepancy-shipments" class="nav-link">Discrepancy Shipments</router-link>
                </li>
                <li class="">
                  <router-link to="/shipment/discrepancy-manifests" class="nav-link">Discrepancy Manifests</router-link>
                </li>
                <li class="">
                  <router-link to="/shipment/pods" class="nav-link">PODs</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list" v-if="currentUser.roles[0] === 'Operator' || currentUser.roles[0] === 'Manager'">
            <a
              :class="operations ? null : 'collapsed'"
              :aria-expanded="operations ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="operations = !operations"
              v-if="currentUser.sbuUser.sbuId !== 3"
            >
              <span class="">OPERATIONS</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <a
              :class="operations ? null : 'collapsed'"
              :aria-expanded="operations ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="operations = !operations"
              v-if="currentUser.sbuUser.sbuId === 3"
            >
              <span class="">OPERATIONS</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-4" v-model="operations" class="mt-2" v-if="currentUser.sbuUser.sbuId !== 3">
              <ul class="list-unstyled">
                <li class="" v-if="currentUser.roles[0] === 'Operator'">
                  <a
                    :class="originHub ? null : 'collapsed'"
                    :aria-expanded="originHub ? 'true' : 'false'"
                    aria-controls="collapse-4"
                    class="nav-link d-flex justify-content-between"
                    @click="originHub = !originHub"
                  >
                    <span class="">Origin Hub</span>
                    <img class="" src="@/assets/img/icons/chevron-r.svg" />
                  </a>
                  <b-collapse id="collapse-4" v-model="originHub" class="mt-2">
                    <div class="side-link-collapse">
                      <ul class="list-unstyled">
                        <li class="">
                          <router-link to="/operations/arrivals/pack-bag" class="nav-link">
                            Pack Bag
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/operations/weighing" class="nav-link">
                            Weighing
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/operations/origin-hub/debagging" class="nav-link">
                            Debagging
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/operations/origin-hub/sorting" class="nav-link">
                            Sorting
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </b-collapse>
                </li>
                <li v-if="currentUser.roles[0] !== 'Manager'">
                  <a
                    :class="dbmHub ? null : 'collapsed'"
                    :aria-expanded="dbmHub ? 'true' : 'false'"
                    aria-controls="collapse-4"
                    class="nav-link d-flex justify-content-between"
                    @click="dbmHub = !dbmHub"
                  >
                    <span class="">Transit Hub</span>
                    <img class="" src="@/assets/img/icons/chevron-r.svg" />
                  </a>
                  <b-collapse id="collapse-4" v-model="dbmHub" class="mt-2">
                    <div class="side-link-collapse">
                      <ul class="list-unstyled">
                        <li class="">
                          <router-link to="/operations/dmb-hub/arrivals" class="nav-link">
                            Departure
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/operations/dmb-hub/weighing" class="nav-link">
                            Weighing
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </b-collapse>
                </li>
                <li v-if="currentUser.roles[0] === 'Operator'">
                  <a
                    :class="destinationHub ? null : 'collapsed'"
                    :aria-expanded="destinationHub ? 'true' : 'false'"
                    aria-controls="collapse-4"
                    class="nav-link d-flex justify-content-between"
                    @click="destinationHub = !destinationHub"
                  >
                    <span class="">Destination Hub</span>
                    <img class="" src="@/assets/img/icons/chevron-r.svg" />
                  </a>
                  <b-collapse id="collapse-4" v-model="destinationHub" class="mt-2">
                    <div class="side-link-collapse">
                      <ul class="list-unstyled">
                        <li class="">
                          <router-link to="/operations/destination/arrivals" class="nav-link">
                            Arrivals
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/operations/destination/weighing" class="nav-link">
                            Weighing
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/operations/destination/debagging" class="nav-link">
                            Debagging
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </b-collapse>
                </li>
                <li class="nav-list" v-if="currentUser.roles[0] === 'Operator'">
            <a
              :class="delivery ? null : 'collapsed'"
              :aria-expanded="delivery ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="delivery = !delivery"
            >
              <span class="">DELIVERY</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-4" v-model="delivery" class="mt-2">
              <div class="side-link-collapse">
                <ul class="list-unstyled">
                  <li class="">
                          <router-link to="/operations/destination/sort-by-destination" class="nav-link">
                            Sorting
                          </router-link>
                        </li>
                  <li class="">
                    <router-link to="/operations/delivery/delivery" class="nav-link">
                      Delivery
                    </router-link>
                  </li>
                </ul>
              </div>
            </b-collapse>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Operator'">
                  <router-link to="/operation/pickups" class="nav-link">Pickups</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Operator'">
                  <router-link to="/operation/courierbags" class="nav-link">Courier Bags</router-link>
                </li>
                <li class="">
                  <router-link to="/shipment/pods" class="nav-link">PODs</router-link>
                </li>
                <li class="">
                  <router-link to="/operations/report/manifest-report" class="nav-link">
                    Manifest Report
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/operations/report/sdr-report" class="nav-link">
                    SDR Report
                  </router-link>
                </li>
                <li class="" v-if="currentUser?.roles[0] == 'Operator'">
                  <router-link to="/operation/dailysales" class="nav-link">Daily Sales</router-link>
                </li>
                <li class="" v-if="currentUser?.roles[0] == 'Operator'">
                  <router-link to="/operation/dailyshipments" class="nav-link">Daily Shipments</router-link>
                </li>
                <li class="">
                  <router-link to="/shipment/importManual" class="nav-link">Upload Manual Shipment</router-link>
                </li>
                <li class="" v-if="currentUser?.sbuUser?.sbuId == 2">
                  <router-link to="/shipment/relocations" class="nav-link">Relocations</router-link>
                </li>
              </ul>
            </b-collapse>
            <b-collapse id="collapse-4" v-model="operations" class="mt-2" v-if="currentUser.sbuUser.sbuId === 3">
              <ul class="list-unstyled">
                <li class="" v-if="currentUser.roles[0] === 'Operator'">
                  <a
                    :class="originHub ? null : 'collapsed'"
                    :aria-expanded="originHub ? 'true' : 'false'"
                    aria-controls="collapse-4"
                    class="nav-link d-flex justify-content-between"
                    @click="originHub = !originHub"
                  >
                    <span class="">Origin Hub</span>
                    <img class="" src="@/assets/img/icons/chevron-r.svg" />
                  </a>
                  <b-collapse id="collapse-4" v-model="originHub" class="mt-2">
                    <div class="side-link-collapse">
                      <ul class="list-unstyled">
                        <li class="">
                          <router-link to="/gsaoperations/arrivals/new" class="nav-link">
                            Pack Bag
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/gsaoperations/weighing" class="nav-link">
                            Weighing
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/gsaoperations/origin-hub/sorting" class="nav-link">
                            Sorting
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/gsaoperations/origin-hub/delayed" class="nav-link">
                            Delayed
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/gsaoperations/unsettled-manifest" class="nav-link">
                            Unsettled Manifest
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </b-collapse>
                </li>
                <li>
                  <a
                    :class="dbmHub ? null : 'collapsed'"
                    :aria-expanded="dbmHub ? 'true' : 'false'"
                    aria-controls="collapse-4"
                    class="nav-link d-flex justify-content-between"
                    @click="dbmHub = !dbmHub"
                  >
                    <span class="">Transit Hub</span>
                    <img class="" src="@/assets/img/icons/chevron-r.svg" />
                  </a>
                  <b-collapse id="collapse-4" v-model="dbmHub" class="mt-2">
                    <div class="side-link-collapse">
                      <ul class="list-unstyled">
                        <li class="">
                          <router-link to="/operations/dmb-hub/arrivals" class="nav-link">
                            Arrivals
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/operations/dmb-hub/weighing" class="nav-link">
                            Weighing
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </b-collapse>
                </li>
                <li v-if="currentUser.roles[0] === 'Operator'">
                  <a
                    :class="destinationHub ? null : 'collapsed'"
                    :aria-expanded="destinationHub ? 'true' : 'false'"
                    aria-controls="collapse-4"
                    class="nav-link d-flex justify-content-between"
                    @click="destinationHub = !destinationHub"
                  >
                    <span class="">Destination Hub</span>
                    <img class="" src="@/assets/img/icons/chevron-r.svg" />
                  </a>
                  <b-collapse id="collapse-4" v-model="destinationHub" class="mt-2">
                    <div class="side-link-collapse">
                      <ul class="list-unstyled">
                        <li class="">
                          <router-link to="/gsaoperations/destination/arrivals" class="nav-link">
                            Arrivals
                          </router-link>
                        </li>
                        <li class="">
                          <router-link to="/gsaoperations/destination/weighing" class="nav-link">
                            Weighing
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </b-collapse>
                </li>
                <li class="nav-list" v-if="currentUser.roles[0] === 'Operator'">
            <a
              :class="delivery ? null : 'collapsed'"
              :aria-expanded="delivery ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="delivery = !delivery"
            >
              <span class="">DELIVERY</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-4" v-model="delivery" class="mt-2">
              <div class="side-link-collapse">
                <ul class="list-unstyled">
                  <li class="">
                          <router-link to="/gsaoperations/destination/sort-by-destination" class="nav-link">
                            Sorting
                          </router-link>
                        </li>
                  <li class="">
                    <router-link to="/gsaoperations/delivery/delivery" class="nav-link">
                      Delivery
                    </router-link>
                  </li>
                </ul>
              </div>
            </b-collapse>
                </li>
                <li class="">
                  <router-link to="/shipment/pods" class="nav-link">PODs</router-link>
                </li>
                <li class="">
                  <router-link to="/gsaoperations/report/manifest-report" class="nav-link">
                    Manifest Report
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator'|| currentUser.roles[0] === 'IT Administrator'">
            <a
              :class="service ? null : 'collapsed'"
              :aria-expanded="service ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="service = !service"
            >
              <span class="">SERVICE</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="service" class="mt-2">
              <ul class="list-unstyled">
                <li class="" >
                  <router-link to="/service/manage-service" class="nav-link">
                    Manage Service
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/service/manage-service-price" class="nav-link">
                    Manage Service Price
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/service/manage-service-location" class="nav-link">
                    Manage Service Location
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/service/manage-special-price" class="nav-link">
                    Manage Special Service Price
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/service/fedex-loc/index" class="nav-link">
                    Manage Fedex Service Loc
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator'">
                  <router-link to="/fserviceprice/index" class="nav-link">
                  Freight Service Price
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator'">
                  <router-link to="/service/manage-comm-price" class="nav-link">
                  Commodity Price
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'IT Administrator' || currentUser.roles[0] === 'FrontLine' || currentUser.roles[0] === 'Manager' || currentUser.roles[0] === 'Operator' || isManager">
            <a
              :class="report ? null : 'collapsed'"
              :aria-expanded="report ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="report = !report"
            >
              <span class="">REPORT</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="report" class="mt-2">
              <ul class="list-unstyled">
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator'">
                  <router-link to="/report/domestic" class="nav-link">
                    RSM Trans Report
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/report/fsm" class="nav-link">
                    FSM Trans Report
                  </router-link>
                </li>
                <!-- <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/report/frontline/fsm" class="nav-link" v-if="currentUser.roles[0] === 'Global Administrator'">
                    FSM Shipment Report
                  </router-link>
                </li> -->
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/report/gsa" class="nav-link">
                    GSA Trans Report
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'FrontLine' || currentUser.roles[0] === 'Manager'">
                  <router-link to="/report/frontline/rsm" class="nav-link" v-if="currentUser.sbuUser.sbuId != 3">
                    RSM Shipment Report
                  </router-link>
                  <router-link to="/shipment/customer-shipments" class="nav-link" v-if="currentUser.sbuUser.sbuId != 3">
                    Corporate Customer Shipments
                  </router-link>
                  <router-link to="/report/frontline/gsa" class="nav-link" v-if="currentUser.sbuUser.sbuId == 3">
                    GSA Shipment Report
                  </router-link>
                  <router-link to="/report/frontline/fsm" class="nav-link" v-if="currentUser.sbuUser.sbuId == 1">
                    FSM Shipment Report
                  </router-link>
                </li>
                <li class="" v-if="(currentUser.roles[0] === 'FrontLine' || currentUser.roles[0] === 'Manager') && currentUser.sbuUser.sbuId === 1">
                  <router-link to="/report/frontline/int" class="nav-link">
                    Intl Shipment Report
                  </router-link>
                </li>
                <li class="" v-if="(currentUser.roles[0] === 'FrontLine' || currentUser.roles[0] === 'Manager') && currentUser.sbuUser.sbuId === 3">
                  <router-link to="/report/frontline/freight" class="nav-link">
                    FShipment Report
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/report/fno" class="nav-link">
                    Shipment Posting Report
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'Manager' || isManager">
                  <router-link to="/report/shipment-report" class="nav-link">
                    Shipment Report
                  </router-link>
                </li>
                <li class="" v-if="showFSMShipmentReport">
                  <router-link to="/report/fsm-shipment-report" class="nav-link">
                    FSM Shipment Report
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'Manager' || isManager">
                  <router-link to="/report/import-shipment-report" class="nav-link">
                    Import Shipment Report
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'Manager' || currentUser.roles[0] === 'Operator' || isManager">
                  <router-link to="/report/exception-report" class="nav-link">
                    Exception Report
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list"  v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'IT Administrator' || currentUser.roles[0] === 'Operator' || currentUser.roles[0] === 'Manager'">
            <a
            :class="settings ? null : 'collapsed'"
            :aria-expanded="settings ? 'true' : 'false'"
            aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="settings = !settings"
            >
              <span class="">SETTINGS</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="settings" class="mt-2">
              <ul class="list-unstyled">
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/bankaccount/index" class="nav-link">Banks</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/airports/index" class="nav-link">Airports</router-link>
                </li>
                 <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/airportCities" class="nav-link">Airport Cities</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/city/index" class="nav-link">City</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/towns/index" class="nav-link">Towns</router-link>
                </li>
                <li class=""  v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/foreigncity/index" class="nav-link">Fedex City</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator'">
                  <router-link to="/onforwarding/index" class="nav-link">
                  Manage Onforwardings
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/hubs/index" class="nav-link">
                  Manage HUBs
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/routines/index" class="nav-link">
                  Manage Routines
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/regions/index" class="nav-link">
                  Manage Regions
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator' || currentUser.roles[0] === 'Operator' || currentUser.roles[0] === 'Manager'">
                  <router-link to="/exceptions" class="nav-link">
                  Manage Exceptions
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Operator' || currentUser.roles[0] === 'Manager' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/drivers/index" class="nav-link">
                  Manage Drivers
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/account_allocations" class="nav-link">
                  Account Allocations
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator' || currentUser.roles[0] === 'Administrator'">
                  <router-link to="/tag_allocations" class="nav-link">
                  Tag Allocations
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/expense/types" class="nav-link">
                  Expense Types
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/settings/charge_settings" class="nav-link">
                  Charge Settings
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list" v-if="currentUser.roles[0] === 'Global Administrator' || currentUser.roles[0] === 'Administrator'">
            <a
              :class="centre ? null : 'collapsed'"
              :aria-expanded="centre ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="centre = !centre"
            >
              <span class="">CENTRE</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="centre" class="mt-2">
              <ul class="list-unstyled">
                <li class="nav-list">
                  <router-link to="/manage-frontline" class="nav-link">
                    Manage frontline
                  </router-link>
                </li>
                <li class="nav-list">
                  <router-link to="/manage-centre" class="nav-link">
                    Manage Centre
                  </router-link>
                </li>
                <li class="nav-list">
                  <router-link to="/manage-centre-city" class="nav-link">
                    Manage Centre City
                  </router-link>
                </li>
                <li class="nav-list">
                  <router-link to="/manage-centre-airport" class="nav-link">
                    Manage Centre Airport
                  </router-link>
                </li>
                <li class="nav-list">
                  <router-link to="/manage-pos" class="nav-link">
                    Manage Centre POS
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list" v-if="currentUser.roles[0] === 'Operator' || currentUser.roles[0] === 'Manager' || currentUser.roles[0] === 'FrontLine'">
            <a
              :class="expenses ? null : 'collapsed'"
              :aria-expanded="expenses ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="expenses = !expenses"
            >
              <span class="">EXPENSES</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="expenses" class="mt-2">
              <ul class="list-unstyled">
                <li class="">
                  <router-link to="/expense/claims" class="nav-link">My Expense Claims</router-link>
                </li>
                <li class="">
                  <router-link to="/expense/cash_advance" class="nav-link">My Cash Advances</router-link>
                </li>
                <!-- <li class="" v-if="currentUser.roles === 'Administrator'">
                  <router-link to="/expense/subsidiary_employee_claims" class="nav-link">Expense Claims</router-link>
                </li> -->
                <li class="" v-if="isManager">
                  <router-link to="/expense/team_claims" class="nav-link">Expense Claims</router-link>
                </li>
                <!-- <li class="" v-if="isManager">
                  <router-link to="/expense/subsidiary_employee_claims" class="nav-link">Expense Claims</router-link>
                </li> -->
                <li class="" v-if="isManager">
                  <router-link to="/expense/cash_advance_team" class="nav-link">Cash Advances</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list" v-if="currentUser.roles[0] === 'Global Administrator'">
            <a
            :class="broadcast ? null : 'collapsed'"
            :aria-expanded="broadcast ? 'true' : 'false'"
            aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="broadcast = !broadcast"
            >
              <span class="">BROADCAST</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="broadcast" class="mt-2">
              <ul class="list-unstyled">
                <li class="nav-list">
                  <router-link to="/broadcast/templates" class="nav-link">
                    SMS Templates
                  </router-link>
                </li>
                <li class="nav-list">
                  <router-link to="/broadcast/create" class="nav-link">
                    Broadcast
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list">
            <a
            :class="profile ? null : 'collapsed'"
            :aria-expanded="profile ? 'true' : 'false'"
            aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="profile = !profile"
            >
              <span class="">PROFILE</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="profile" class="mt-2">
              <ul class="list-unstyled">
                <li class="nav-list">
                  <router-link to="/changepassword" class="nav-link">
                    Change Password
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-list" v-if="currentUser.roles[0] === 'IT Administrator'">
            <a
              :class="admin ? null : 'collapsed'"
              :aria-expanded="admin ? 'true' : 'false'"
              aria-controls="collapse-4"
              class="nav-link d-flex justify-content-between"
              @click="admin = !admin"
            >
              <span class="">ADMIN</span>
              <img class="" src="@/assets/img/icons/chevron-r.svg" />
            </a>
            <b-collapse id="collapse-9" v-model="admin" class="mt-2">
              <ul class="list-unstyled">
                <li class="" v-if="currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/admin/subadmin/index" class="nav-link">Manage Sub-Admin</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'IT Administrator'">
                <router-link to="/manage-manager" class="nav-link">
                    Managers
                  </router-link>
                </li>
                <li class="nav-list" v-if="currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/manage-operator" class="nav-link">
                    Manage Operator
                  </router-link>
                </li>
                <li class="nav-list" v-if="currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/operational_managers" class="nav-link">
                    Operational Managers
                  </router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/admin/globaladmin/index" class="nav-link">Manage Global Admin</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/admin/subsidiaries/index" class="nav-link">Manage Subsidiary</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/tag_allocations" class="nav-link">Tag Allocations</router-link>
                </li>
                <li class="" v-if="currentUser.roles[0] === 'IT Administrator'">
                  <router-link to="/admin/udailysales" class="nav-link">Unverified DSS</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="">
                  <router-link to="/shipment/view" class="nav-link">Track Shipment</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
