import { defineStore, acceptHMRUpdate } from 'pinia'
import { coreService } from '../services/CoreService'
import { miscService } from '../services/MiscService'
import { authenticationService } from '../services/AuthenticationService'

export const useCoreStore = defineStore('CoreStore', {
  state: () => {
    return {
      subsidiaries: [],
      TagSbuId: 3,
      users: [],
      currentUser: authenticationService.currentUserValue ? authenticationService.currentUserValue.user : null,
      userId: authenticationService.currentUserValue.user ? authenticationService.currentUserValue.user.id : null,
      subsidiaryId: authenticationService.currentUserValue.user.sbuUser ? authenticationService.currentUserValue.user.sbuUser.sbuId : null,
      services: [],
      service: {},
      newService: {
        sbuId: null,
        name: '',
        code: '',
        type: ''
      },
      serviceId: '',
      gsaAirlines: ['ARIK', 'AERO CONTRACTORS', 'AZMAN', 'IBOM AIR', 'MAX AIR', 'NG EAGLE'],
      deliveryType: [{ id: 1, title: 'Express delivery', sbuId: 1 }, { id: 2, title: 'Next Am morning delivery', sbuId: 1 }, { id: 4, title: 'Hold for collection', sbuId: 1 }, { id: 0, title: 'Truckage', sbuId: 2 }, { id: 4, title: 'Kilogram', sbuId: 2 }, { id: 1, title: 'Volume', sbuId: 2 }],
      serviceLocations: [],
      serviceLocation: {},
      newServiceLocation: {
        sbuId: null,
        serviceId: null,
        originId: null,
        destinationId: null,
        reversable: true
      },
      serviceLocId: '',
      prices: [],
      towns: [],
      file: null,
      fileName: '',
      price: {},
      newPrice: {
        sbuId: null,
        serviceId: null
      },
      priceId: '',
      specialPrices: [],
      specialPrice: {},
      newSPrice: {
        sbuId: 3,
        originId: null,
        destinationId: null,
        serviceId: null
      },
      newSSPrice: {
        sbuId: null,
        originId: null,
        destinationId: null,
        serviceCode: ''
      },
      specialPriceId: '',
      onforwardings: [],
      onforwarding: {},
      onforwardId: '',
      newOnforward: {
        sbuId: null,
        cityId: null,
        townId: null
      },
      cityId: null,
      originId: null,
      destId: null,
      topup: {},
      allocations: [],
      newAllocation: {
        selectedCentre: 3,
        noOfAllocations: 0
      },
      tagAllocations: [],
      newTagAllocation: {
        selectedSbuId: null,
        noOfAllocations: 0
      },
      centres: [],
      centreId: null,
      types: [
        {
          id: 1,
          name: 'Domestic'
        },
        {
          id: 2,
          name: 'Fedex'
        },
        {
          id: 2,
          name: 'TNT'
        }
      ],
      loading: false,
      error: null,
      action: false,
      view: false,
      deleteModal: false,
      actionType: 'add',
      searchParam: '',
      accounts: null,
      operationManagers: [],
      managerId: null,
      manager: {},
      newManager: {
        userId: null,
        managerId: null
      },
      isManager: false,
      expenseClaims: [],
      expenseId: null,
      expense: {
        // date: '',
        // assignee: authenticationService.currentUserValue
        //   ? authenticationService.currentUserValue.user.id
        //   : null,
        // remark: '',
        // expenseTypeId: null,
        // amount: 0,
        // expenseClaimItems: []
      },
      newClaim: {
        date: '',
        assignee: authenticationService.currentUserValue
          ? authenticationService.currentUserValue.user.id
          : null,
        remark: '',
        expenseTypeId: null,
        amount: 0,
        expenseClaimItems: []
      },
      expenseCollapse: true,
      expItemCollapse: false,
      expenseItem: {},
      newExpenseItem: {
        sbuId: null,
        payeeBankName: null
      },
      expenseFile: null,
      expenseFileName: '',
      expenseItemId: 0,
      expenseTypes: [],
      expTypeId: null,
      expenseType: {},
      newExpType: {},
      cashAdvances: [],
      advanceId: null,
      advance: {},
      newAdvance: {
        date: '',
        requestBy: authenticationService.currentUserValue
          ? authenticationService.currentUserValue.user.id
          : null,
        description: '',
        amount: 0,
        paymentOption: '',
        payeeBankName: '',
        payeeAccountNumber: '',
        payeeAccountName: ''
      },
      cashRetirements: [],
      newRetirementLine: {},
      retirementLine: {},
      retirementLineId: null,
      retirementId: null,
      cashRetirement: {},
      newRetirement: {
        date: '',
        retiredBy: authenticationService.currentUserValue
          ? authenticationService.currentUserValue.user.id
          : null,
        postingDescription: '',
        retiredAmount: 0,
        reimburseAmount: 0
      }
    }
  },
  getters: {
    filteredServicePrices: (state) => {
      let filteredPrices = state.prices
      if (state.searchParam !== '' && state.searchParam) {
        filteredPrices = filteredPrices.filter((p) => {
          const code = p.service?.code?.toLowerCase()
          // const name = p.service?.name?.toLowerCase()
          return code?.includes(state.searchParam)
        })
      }
      return filteredPrices
    },
    filteredServices: (state) => {
      let filteredServices = state.services
      if (state.searchParam !== '' && state.searchParam) {
        filteredServices = filteredServices.filter((s) => {
          // const code = s.code?.toLowerCase()
          const name = s.name?.toLowerCase()
          const type = s.type?.toLowerCase()
          return name?.includes(state.searchParam) || type?.includes(state.searchParam)
        })
      }
      return filteredServices
    },
    filteredLocations: (state) => {
      let filteredLocations = state.serviceLocations
      if (state.searchParam !== '' && state.searchParam) {
        filteredLocations = filteredLocations.filter((l) => {
          const origin = l.origin?.name?.toLowerCase()
          const destination = l.destination?.name?.toLowerCase()
          const service = l.service?.name?.toLowerCase()
          const serviceC = l.service?.code?.toLowerCase()
          return origin?.includes(state.searchParam) || destination?.includes(state.searchParam) || service?.includes(state.searchParam) || serviceC?.includes(state.searchParam)
        })
      }
      return filteredLocations
    },
    filteredSPrices: (state) => {
      let priceFiltered = state.specialPrices
      if (state.searchParam !== '' && state.searchParam) {
        priceFiltered = priceFiltered.filter((p) => {
          const code = p.service?.code.toLowerCase()
          const origin = p.origin?.name?.toLowerCase()
          const destination = p.destination?.name?.toLowerCase()
          return code.includes(state.searchParam) || origin.includes(state.searchParam) || destination.includes(state.searchParam)
        })
      }
      return priceFiltered
    },
    filteredOnforwards: (state) => {
      let onforwards = state.onforwardings
      if (state.searchParam !== '' && state.searchParam) {
        onforwards = onforwards.filter((f) => {
          const town = f.town?.name?.toLowerCase()
          return town.includes(state.searchParam)
        })
      }
      return onforwards
    }
  },
  actions: {
    getSubsidiaries () {
      miscService.getSBUs()
        .then(
          s => {
            this.subsidiaries = s
            console.log('subs >>', s)
          }
        )
    },
    getPrices (id) {
      this.loading = true
      coreService.getServicePrices(id)
        .then(
          p => {
            console.log('prices >>', p)
            this.prices = p
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getCentres () {
      miscService.getCentres(1)
        .then(
          c => {
            this.centres = c
            console.log('centres >>', c)
          },
          error => {
            console.log(error)
            // alert(error)
          }
        )
    },
    getAllocations (id) {
      this.loading = true
      coreService.getAllocations(id)
        .then(
          a => {
            console.log('allocations >>', a)
            this.allocations = a
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getTagAllocations (id) {
      this.loading = true
      coreService.getTagAllocations(id)
        .then(
          a => {
            console.log('allocations >>', a)
            this.tagAllocations = a
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getServices (id) {
      this.loading = true
      coreService.getServices(id)
        .then(
          s => {
            console.log('services >>', s)
            this.services = s
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getServiceLocations (id, loc) {
      this.loading = true
      coreService.getServiceLocations(id, loc)
        .then(
          sl => {
            console.log('serviceLocs >>', sl)
            this.serviceLocations = sl
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getSServicePrices (sbuId, orgId, destId) {
      this.loading = true
      coreService.getSSPrices(sbuId, orgId, destId)
        .then(
          ssP => {
            console.log('specialSPrices >>', ssP)
            this.specialPrices = ssP
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getIServiceLocations (id) {
      this.loading = true
      coreService.getIServiceLocations(id)
        .then(
          sl => {
            console.log('serviceLocs >>', sl)
            this.serviceLocations = sl
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getFServicePrices () {
      this.loading = true
      coreService.getFServicePrices()
        .then(
          fsP => {
            console.log('freightSPrices >>', fsP)
            this.specialPrices = fsP
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getCServicePrices (id) {
      this.loading = true
      coreService.getCServicePrices(id)
        .then(
          fsP => {
            console.log('specialPrices >>', fsP)
            this.specialPrices = fsP
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getIServicePrices (id) {
      this.loading = true
      coreService.getIServicePrices(id)
        .then(
          fsP => {
            console.log('specialPrices >>', fsP)
            this.specialPrices = fsP
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getCFServicePrices (id) {
      this.loading = true
      coreService.getCFServicePrices(id)
        .then(
          fsP => {
            console.log('specialPrices >>', fsP)
            this.specialPrices = fsP
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getFServicePriceById (id) {
      this.loading = true
      coreService.getFServicePriceById(id)
        .then(
          fsP => {
            console.log('specialPrices >>', fsP)
            this.specialPrices = fsP
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getCFServicePriceById (id) {
      this.loading = true
      coreService.getCFServicePriceById(id)
        .then(
          fsP => {
            console.log('specialPrices >>', fsP)
            this.specialPrices = fsP
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    },
    getOnforwardings (sbuId, cityId) {
      this.loading = true
      coreService.getOnforwardings(sbuId, cityId)
        .then(
          onF => {
            console.log('onforward >>', onF)
            this.onforwardings = onF
            this.loading = false
          },
          e => {
            console.log(e)
            alert(e)
            this.loading = false
          }
        )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCoreStore, import.meta.hot))
}
